<template>
  <div>
    <div v-if="isTech || isSalesRep">
      <div class="t-text-sm t-ext-gray-500 t-font-light t-mb-6">
        Average Rating
      </div>

      <div class="t-flex t-space-x-6 t-items-center t-mb-6">
        <div class="t-flex t-items-center t-justify-center t-bg-yellow-100 t-h-10 t-w-10 t-rounded-md">
          <i class="material-icons-outline md-star_border t-text-yellow-400 t-text-2xl t-font-light" />
        </div>
        <div class="t-text-gray-500 t-italic t-text-base">
          Not available
        </div>
        <!--<div class="t-text-gray-800 t-text-2xl">-->
        <!--  4-->
        <!--</div>-->
      </div>

      <el-divider />
    </div>

    <div>
      <div class="t-text-sm t-ext-gray-500 t-font-light t-mb-6">
        Jobs Completed
      </div>

      <div class="t-flex t-space-x-6 t-items-center t-mb-6">
        <div class="t-flex t-items-center t-justify-center t-bg-blue-100 t-h-10 t-w-10 t-rounded-md">
          <i class="material-icons-outline md-business_center t-text-blue-400 t-text-2xl t-font-light" />
        </div>
        <div class="t-text-gray-800 t-text-2xl">
          {{ totalJobs }}
        </div>
      </div>

      <el-divider />
    </div>

    <div v-if="isTech">
      <div class="t-text-sm t-ext-gray-500 t-font-light t-mb-6">
        Warranty Jobs
      </div>

      <div class="t-flex t-space-x-6 t-items-center t-mb-6">
        <div class="t-flex t-items-center t-justify-center t-bg-orange-100 t-h-10 t-w-10 t-rounded-md">
          <i class="material-icons-outline md-verified_user t-text-orange-400 t-text-2xl t-font-light" />
        </div>
        <div class="t-text-gray-800 t-text-2xl">
          {{ warrantyPercent }}
        </div>
      </div>

      <el-divider />
    </div>

    <div v-if="isTech || isSalesRep">
      <div class="t-text-sm t-ext-gray-500 t-font-light t-mb-6">
        Customer Comments
      </div>

      <div class="t-flex t-space-x-6 t-items-center t-mb-6">
        <div class="t-flex t-items-center t-justify-center t-bg-red-100 t-h-10 t-w-10 t-rounded-md">
          <i class="material-icons-outline md-sms t-text-red-400 t-text-2xl t-font-light" />
        </div>
        <div class="t-text-gray-500 t-italic t-text-base">
          Not available
        </div>
        <!--<div class="t-text-gray-800 t-text-2xl">-->
        <!--  432-->
        <!--</div>-->
      </div>

      <el-divider />
    </div>

    <div>
      <div class="t-text-sm t-ext-gray-500 t-font-light t-mb-6">
        Lifetime Commissions
      </div>

      <div class="t-flex t-space-x-6 t-items-center t-mb-6">
        <div class="t-flex t-items-center t-justify-center t-bg-green-100 t-h-10 t-w-10 t-rounded-md">
          <i class="material-icons-outline md-monetization_on t-text-green-400 t-text-2xl t-font-light" />
        </div>
        <div class="t-text-gray-800 t-text-2xl">
          ${{ totalCommissions }}
        </div>
      </div>

      <el-divider />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { VendorsAPI } from '@/services/VendorsAPI'
import { useVendorStore } from '@/components/Vendors/Profile/vendor.store'

export default {
  data() {
    return {
      totalJobs: 0,
      totalCommissions: 0,
      warrantyPercent: '0%'
    }
  },

  computed: {
    ...mapState(useVendorStore, ['data', 'isTech', 'isSalesRep'])
  },

  async created() {
    const id = this.data.id
    this.totalJobs = await VendorsAPI.get(`${id}/total-jobs`)
    this.totalCommissions = Math.round(await VendorsAPI.get(`${id}/total-commissions`) * 100) / 100

    if (this.isTech) {
      this.warrantyPercent = await VendorsAPI.get(`${id}/warranty-percent`)
    }
  }
}
</script>
