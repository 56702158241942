export const processTech = (vendor, parts, jobId) => {
  let hasChipPart = false
  parts.forEach(part => {
    if (part.description === 'Chip Repair') {
      hasChipPart = true
    }
  })

  const commissionAmount = hasChipPart ? +vendor.chip_commission?.amount : +vendor.commission?.amount

  let commissionType = 'flat'
  if (hasChipPart) {
    if (vendor.chip_commission?.is_percent) {
      if (vendor.chip_commission.is_percent === 1) {
        commissionType = 'percent'
      } else if (vendor.chip_commission.is_percent === 2) {
        commissionType = 'percent_subtotal'
      } else if (vendor.chip_commission.is_percent === 3) {
        commissionType = 'percent_margin'
      }
    }
  } else {
    if (vendor.commission?.is_percent) {
      if (vendor.commission.is_percent === 1) {
        commissionType = 'percent'
      } else if (vendor.commission.is_percent === 2) {
        commissionType = 'percent_subtotal'
      } else if (vendor.commission.is_percent === 3) {
        commissionType = 'percent_margin'
      }
    }
  }

  return {
    gb_vendor_id: vendor.id,
    name: vendor.name,
    gb_vendor_type_id: 4,
    commission: commissionAmount,
    commission_flat_or_percent: commissionType,
    job_id: jobId
  }     
}
