var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "470px",
        "append-to-body": "",
        "destroy-on-close": ""
      },
      on: { close: _vm.close }
    },
    [
      _c(
        "span",
        {
          staticClass: "t-text-gray-700 t-text-lg",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("span", { staticClass: "t-ml-3" }, [
            _vm._v(" Reassign "),
            _c(
              "span",
              { staticStyle: { "font-weight": "bold", color: "#F56C6C" } },
              [_vm._v(" ALL " + _vm._s(_vm.jobIds.length) + " JOBS ")]
            ),
            _vm._v(" on this route ")
          ])
        ]
      ),
      _c("div", { staticClass: "t-flex t-justify-between" }, [
        _c("div", [
          _c("div", [_vm._v(" From ")]),
          _c("div", [_vm._v(" " + _vm._s(_vm.tech.name) + " ")])
        ]),
        _c("div", [
          _c("div", [_vm._v(" To ")]),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  attrs: { "value-key": "id", size: "mini", filterable: "" },
                  model: {
                    value: _vm.selectedTech,
                    callback: function($$v) {
                      _vm.selectedTech = $$v
                    },
                    expression: "selectedTech"
                  }
                },
                _vm._l(_vm.availableTechs, function(aTech) {
                  return _c("el-option", {
                    key: aTech.id,
                    attrs: { value: aTech, label: aTech.name }
                  })
                }),
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "t-align-center",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.saving,
                type: "primary",
                disabled: !_vm.selectedTech
              },
              on: { click: _vm.reassignJobs }
            },
            [_vm._v("Reassign")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }