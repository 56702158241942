var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.jobDetails,
            expression: "jobDetails"
          }
        ],
        staticClass: "scheduler-job-details"
      },
      [
        _c(
          "a",
          {
            style: {
              height: "12px",
              "font-size": "12px",
              "padding-left": "14px",
              cursor: "pointer"
            },
            attrs: { size: "mini" },
            on: { click: _vm.saveComplete }
          },
          [_vm._v(" Save as " + _vm._s(_vm.jobCompleteStatus) + " ")]
        ),
        _c("div", { staticClass: "header" }, [
          _c("h4", [
            _c("span", [_vm._v("Job Details ")]),
            _vm.jobDetails
              ? _c(
                  "a",
                  { attrs: { target: "_blank", href: "/jobs/" + _vm.job.id } },
                  [_vm._v(_vm._s(_vm.orderNumber))]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            { on: { click: _vm.closeWindow } },
            [_c("v-icon", { attrs: { name: "times", scale: "0.8" } })],
            1
          )
        ]),
        _vm.jobDetails
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _vm._l(_vm.infoRows, function(rows, index) {
                  return _c("table", { key: index }, [
                    _c(
                      "tbody",
                      [
                        rows === "editAddress"
                          ? _c("tr", [
                              _vm.editAddress
                                ? _c(
                                    "td",
                                    [
                                      _c("places-autocomplete", {
                                        staticClass: "autocomplete",
                                        attrs: {
                                          initialValue: _vm.addressString,
                                          full: true
                                        },
                                        on: {
                                          "place-selected": _vm.placeSelected
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._l(rows, function(row) {
                              return _c(
                                "job-details-info-block",
                                _vm._b(
                                  {
                                    key: row.label,
                                    attrs: { shopId: _vm.jobDetails.shop_id },
                                    on: {
                                      "edit-address": function($event) {
                                        _vm.editAddress = !_vm.editAddress
                                      },
                                      openConsumerDialog:
                                        _vm.openConsumerDialog,
                                      "update-tech": _vm.updateTech
                                    }
                                  },
                                  "job-details-info-block",
                                  row,
                                  false
                                )
                              )
                            })
                      ],
                      2
                    )
                  ])
                }),
                _vm.job
                  ? _c("job-confirmation-details", {
                      attrs: {
                        details: _vm.job.getConfirmationDetails(),
                        job: Object.assign({}, _vm.job, {
                          vehicle: _vm.job.job_vehicle
                        })
                      }
                    })
                  : _vm._e(),
                _c("consumer-dialog", {
                  attrs: { consumer: _vm.consumer },
                  on: { "on-save": _vm.onSaveConsumer },
                  model: {
                    value: _vm.consumerDialogOpen,
                    callback: function($$v) {
                      _vm.consumerDialogOpen = $$v
                    },
                    expression: "consumerDialogOpen"
                  }
                })
              ],
              2
            )
          : _vm._e(),
        _vm.job.techside_sent
          ? _c(
              "div",
              { staticClass: "floating-button" },
              [
                _c(
                  "el-badge",
                  {
                    staticClass: "techside-badge",
                    attrs: { type: "success", value: "TechSIDE" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.job.techside_job_completed
                            ? "success"
                            : "default",
                          size: "small"
                        },
                        on: { click: _vm.completeJob }
                      },
                      [
                        _c("v-icon", {
                          attrs: {
                            name: _vm.completing ? "spinner" : "check",
                            spin: _vm.completing,
                            scale: "0.6"
                          }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.job.techside_job_completed
                                ? "Completed"
                                : "Complete"
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }