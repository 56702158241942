import { gql } from 'apollo-boost'
import { JOB_DETAILS_FRAGMENT } from '../fragments/job-details.fragment'
import { assignmentFields } from '../fragments/templates'

export const JOB_DETAILS_QUERY = gql`
  query getJob($id: ID!, $draft_id: ID) @client {
    job(id: $id, draft_id: $draft_id) {
      ...JobDetailsFragment
      job_gb_csr {
        ${assignmentFields}
        chip_commission {
          amount,
          is_percent
        }
      }
      job_gb_salesource {
        ${assignmentFields}
      }
      job_gb_salesrep {
        ${assignmentFields}
        chip_commission {
          amount,
          is_percent
        }
      }
      job_gb_tech {
        ${assignmentFields}
        chip_commission {
          amount,
          is_percent
        }
      }
      job_gb_location {
        ${assignmentFields}
      }
    }
  }
  ${JOB_DETAILS_FRAGMENT}
`
