<template>
  <el-dialog
    :visible="visible"
    width="470px"
    append-to-body
    destroy-on-close
    @close="close"
  >
  <span slot="title" class="t-text-gray-700 t-text-lg">
    <span class="t-ml-3">
      Reassign 
      <span style="font-weight: bold; color: #F56C6C">
        ALL {{jobIds.length}} JOBS 
      </span>
      on this route
    </span>
  </span>
    <div class="t-flex t-justify-between">
      <div>
        <div>
          From
        </div>
        <div>
          {{tech.name}}
        </div>
      </div>
      <div>
        <div>
          To
        </div>
        <div>
          <el-select
            v-model="selectedTech"
            value-key="id"
            size="mini"
            filterable
          >
            <el-option
              v-for="aTech in availableTechs"
              :key="aTech.id"
              :value="aTech"
              :label="aTech.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div slot="footer" class="t-align-center">
      <el-button type="" @click="$emit('close')">Cancel</el-button>
      <el-button :loading="saving" type="primary" :disabled="!selectedTech" @click="reassignJobs">Reassign</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sync, get, call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'

export default {
  props: {
    'tech': Object,
    'jobIds': Array,
    'visible': Boolean
  },
  data() {
    return {
      selectedTech: null,
      saving: false
    }
  },
  computed: {
    techs: get('sch_v3/techs'),
    jobDetails: sync('sch_v3/jobDetails'),
    availableTechs() {
      return this.techs?.filter((tech) => tech.id !== this.tech.id) || []
    }
  },
  methods: {
    getSchedulerData: call(actions.getSchedulerData),
    async reassignJobs() {
      this.saving = true
      const req = {
        job_ids: this.jobIds,
        update: {
          gb_tech_id: this.selectedTech.id,
          tech_id: this.selectedTech.old_id
        },
      }

      let updateCommissions = false
      try {
        await this.$messageBox.confirm(
          'Do you want to update commissions?',
          'Warning',
          {
            confirmButtonText: 'Update Commission',
            cancelButtonText: 'No',
            type: 'warning'
          }
        )
        updateCommissions = true
      } catch {}

      await this.$unum.job().post('data/techUpdate/bulk', {...req, updateCommissions})
      this.jobDetails = null
      this.$message.success('Route successfully reassigned')
      this.getSchedulerData()
      this.close()
    },
    close() {
      this.saving = false
      this.selectedTech = null
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>