var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isTech || _vm.isSalesRep
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "t-text-sm t-ext-gray-500 t-font-light t-mb-6" },
              [_vm._v(" Average Rating ")]
            ),
            _vm._m(0),
            _c("el-divider")
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "t-text-sm t-ext-gray-500 t-font-light t-mb-6" },
          [_vm._v(" Jobs Completed ")]
        ),
        _c("div", { staticClass: "t-flex t-space-x-6 t-items-center t-mb-6" }, [
          _vm._m(1),
          _c("div", { staticClass: "t-text-gray-800 t-text-2xl" }, [
            _vm._v(" " + _vm._s(_vm.totalJobs) + " ")
          ])
        ]),
        _c("el-divider")
      ],
      1
    ),
    _vm.isTech
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "t-text-sm t-ext-gray-500 t-font-light t-mb-6" },
              [_vm._v(" Warranty Jobs ")]
            ),
            _c(
              "div",
              { staticClass: "t-flex t-space-x-6 t-items-center t-mb-6" },
              [
                _vm._m(2),
                _c("div", { staticClass: "t-text-gray-800 t-text-2xl" }, [
                  _vm._v(" " + _vm._s(_vm.warrantyPercent) + " ")
                ])
              ]
            ),
            _c("el-divider")
          ],
          1
        )
      : _vm._e(),
    _vm.isTech || _vm.isSalesRep
      ? _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "t-text-sm t-ext-gray-500 t-font-light t-mb-6" },
              [_vm._v(" Customer Comments ")]
            ),
            _vm._m(3),
            _c("el-divider")
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "t-text-sm t-ext-gray-500 t-font-light t-mb-6" },
          [_vm._v(" Lifetime Commissions ")]
        ),
        _c("div", { staticClass: "t-flex t-space-x-6 t-items-center t-mb-6" }, [
          _vm._m(4),
          _c("div", { staticClass: "t-text-gray-800 t-text-2xl" }, [
            _vm._v(" $" + _vm._s(_vm.totalCommissions) + " ")
          ])
        ]),
        _c("el-divider")
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "t-flex t-space-x-6 t-items-center t-mb-6" },
      [
        _c(
          "div",
          {
            staticClass:
              "t-flex t-items-center t-justify-center t-bg-yellow-100 t-h-10 t-w-10 t-rounded-md"
          },
          [
            _c("i", {
              staticClass:
                "material-icons-outline md-star_border t-text-yellow-400 t-text-2xl t-font-light"
            })
          ]
        ),
        _c("div", { staticClass: "t-text-gray-500 t-italic t-text-base" }, [
          _vm._v(" Not available ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-flex t-items-center t-justify-center t-bg-blue-100 t-h-10 t-w-10 t-rounded-md"
      },
      [
        _c("i", {
          staticClass:
            "material-icons-outline md-business_center t-text-blue-400 t-text-2xl t-font-light"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-flex t-items-center t-justify-center t-bg-orange-100 t-h-10 t-w-10 t-rounded-md"
      },
      [
        _c("i", {
          staticClass:
            "material-icons-outline md-verified_user t-text-orange-400 t-text-2xl t-font-light"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "t-flex t-space-x-6 t-items-center t-mb-6" },
      [
        _c(
          "div",
          {
            staticClass:
              "t-flex t-items-center t-justify-center t-bg-red-100 t-h-10 t-w-10 t-rounded-md"
          },
          [
            _c("i", {
              staticClass:
                "material-icons-outline md-sms t-text-red-400 t-text-2xl t-font-light"
            })
          ]
        ),
        _c("div", { staticClass: "t-text-gray-500 t-italic t-text-base" }, [
          _vm._v(" Not available ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "t-flex t-items-center t-justify-center t-bg-green-100 t-h-10 t-w-10 t-rounded-md"
      },
      [
        _c("i", {
          staticClass:
            "material-icons-outline md-monetization_on t-text-green-400 t-text-2xl t-font-light"
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }