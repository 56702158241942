
<template>
  <div
    class="scheduler-job-card"
    @click="viewJobDetails(jobObj.id)"
    :class="{
      active: isActive,
      solid: solidCards,
      faded: isFaded
    }"
    :style="cardStyle"
  >
    <span
      class="notification left orange"
      id="afdds"
      v-if="job.status === 'warranty'"
      >W</span
    >
    <span
      class="notification left purple"
      id="afddt"
      v-if="job.status === 'recal'"
      >R</span
    >
    <span
      class="notification left light-blue"
      id="afddu"
      v-if="job.status === 'callBack'"
      >C</span
    >
    <span
      class="notification left teal"
      id="afddv"
      v-if="job.status === 'rAndI'"
      >{{`R&I`}}</span
    >
    <span
      class="notification left brown"
      id="afddw"
      v-if="job.status === 'tint'"
      >T</span
    >
    <span
      class="notification left dark-blue"
      id="afddx"
      v-if="job.status === 'pickUp'"
      >P</span
    >
    <span
      class="notification left dark-purple"
      id="afddy"
      v-if="job.status === 'dropOff'"
      >D</span
    >
    <div
      v-if="routeEntry.color"
      class="left-border"
      :style="{ background: routeEntry.color }"
    ></div>
    <div v-if="job.customer_cancel == '1'" id="watermark-cancel">CANCEL</div>
    <div v-if="job.customer_reschedule == '1'" id="watermark-reschedule">
      RESCHEDULE
    </div>
    <div></div>
    <div class="invoice-job" v-if="showClose" @click="saveInvoice">
      <v-icon name="times" scale="0.9" v-b-tooltip.hover="'Save job as invoice'"/>
    </div>
    <div
      class="icon-container"
      :class="{ 'has-techside-sent': showClose }"
    >
      <v-icon
        v-for="icon in icons"
        v-bind:key="icon.name"
        v-b-tooltip.hover="icon.title"
        v-bind="icon"
      />
    </div>
    <job-card-info-block
      icon="user-tie"
      :text="jobObj.getTitle()"
      :strong="true"
    />

    <job-card-info-block
      icon="car"
      :text="jobObj.getVehicle()"
      v-if="
        isExpanded && jobObj.getVehicle() && jobObj.getVehicle().trim() !== '0'
      "
      :noLower="true"
    />
    <job-card-info-block
      icon="building"
      :text="jobObj.getShop()"
      v-if="isExpanded && !isBay"
    />

    <job-card-info-block
      icon="map-marker-alt"
      :text="jobObj.getLocation()"
      v-if="isFull && !isBay"
      :noLower="true"
    />
    <job-card-info-block
      icon="map-signs"
      :text="jobObj.getCrossStreets()"
      v-if="isFull && !isBay"
    />
    <div v-if="showTags">
      <div class='scheduler-section-header'>Tags</div>
      <div class="tags">
        <el-tag
          v-for="tag in jobObj.getTags()"
          :key="tag.id"
          :color="tag.color"
          size="small"
          :style="{
            color: tagColor(tag.color),
            borderColor: tag.color
          }"
        >
          {{ tag.text }}
        </el-tag>
      </div>
    </div>
    <div class="vendors" v-if="showVendors">
      <div class='scheduler-section-header'>Vendors</div>
      <scheduler-vendors :vendors="vendors"/>
    </div>
    <div class="inline" v-if="noDate">
      <job-card-info-block
        v-if="jobObj.getTimeWithMinutes()"
        icon="clock"
        :text="jobObj.getTimeWithMinutes()"
        :small="true"
        :solid="solidCards"
      />
      <scheduler-times-row :item="routeEntry" :solid="solidCards" />
    </div>
    <template v-else>
      <div class="inline" v-if="jobObj.getDate() || jobObj.getTimeWithMinutes()">
        <job-card-info-block
          icon="calendar"
          :text="jobObj.getTimeWithMinutes()"
          :small="true"
          :solid="solidCards"
        />
        <job-card-info-block
          icon="clock"
          :text="jobObj.getTimeWithMinutes()"
          :small="true"
          :solid="solidCards"
        />
      </div>
      <div class="inline" v-else>
        <job-card-info-block
          icon="calendar"
          text="No date scheduled"
          :small="true"
          :solid="solidCards"
        />
      </div>
    </template>

    <job-confirmation-details
      v-if="jobConfirmationDetailsVisible"
      :details="jobObj.getConfirmationDetails()"
      :job="{...jobObj, vehicle: jobObj.job_vehicle}"
    />
  </div>
</template>

<script>
import { JobWrapperV2 } from '@/scripts/helpers/job.helpers'
import JobCardInfoBlock from './helpers/JobCardInfoBlock'
import { shedulerColors } from '@/constants/sheduler.configs'
import { eqIds } from '@/scripts/helpers/helpers'
import { prop } from 'ramda'
import { get, call } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import JobConfirmationDetails from './JobConfirmationDetails'
import { getBlackOrWhite } from '@/scripts/helpers/utilities'
import SchedulerTimesRow from './helpers/SchedulerTimesRow'
import { formatToDb } from '@/scripts/helpers/date.helpers'
import SchedulerVendors from './SchedulerVendors'
import Job from '@/scripts/objects/job'

export default {
  props: ['job', 'color', 'cardStyle', 'routeEntry', 'noDate'],
  methods: {
    viewJobDetails: call(actions.getJobDetails),
    tagColor(color) {
      return getBlackOrWhite(color) || '#000'
    },
    updateJobDetailsValue: call(actions.updateJobDetailsValue),
    saveInvoice(e) {
      e.stopPropagation()
      this.updateJobDetailsValue({
        key: 'status',
        value: 'invoice',
        id: this.jobObj.id
      })
      this.updateJobDetailsValue({
        key: 'invoice_date',
        value: formatToDb(new Date()),
        id: this.jobObj.id
      })

      const shop = this.$store.state.shops.find(o => o.id === this.jobObj.shop_id)
      if (shop && shop.auto_qb_export_invoices) {
        const self = this
        Job.exportJobToQBO(this.jobObj.shop_id, this.jobObj.id, true, shop.auto_qb_export_payments, function (results) {
          if (self?.$job?.job?.id === self.jobObj.id) {
            self.$job.loadQuickBooksDetails(false)
          }
        })
      }
    }
  },
  computed: {
    isRowView: get('sch_v3/isRowView'),
    cardView: get('sch_v3/filters@cardView'),
    solidCards: get('sch_v3/filters@solidCards'),
    dayCounters: get('sch_v3/filters@dayCounters'),
    context: get('sch_v3/filters@context'),
    showTags: get('sch_v3/filters@showTags'),
    showVendors: get('sch_v3/filters@showVendors'),
    jobConfirmationDetailsVisible: get(
      'sch_v3/filters@jobConfirmationDetailsVisible'
    ),
    showClose() {
      return this.jobObj.getTechSideSent() && this.job.status === 'workOrder'
    },
    isFaded() {
      return (
        !this.dayCounters[this.jobObj.getMidTime()] ||
        (this.context !== 'all' && this.job.install_context !== this.context)
      )
    },
    jobObj() {
      return new JobWrapperV2(this.job)
    },
    isExpanded() {
      return this.cardView > 0
    },
    isFull() {
      return this.cardView > 1
    },
    isBay() {
      return this.cardView === 4
    },
    inShop() {
      return this.job.install_context === 'inShop'
    },
    vendors() {
      return this.jobObj.getVendors() || []
    },
    icons() {
      return [
        {
          display: this.inShop,
          name: 'home',
          title: 'In Shop',
          color: this.color,
          scale: '0.8'
        },
        {
          display: this.jobObj.getPartsOnBackorder(),
          name: 'cart-arrow-down',
          title: 'Parts on backorder',
          color: this.color,
          scale: '0.8'
        },
        {
          display: this.jobObj.getPendingInspection(),
          name: 'paste',
          title: 'Pending inspection',
          color: this.color,
          scale: '0.8'
        },
        {
          display: this.jobObj.getPendingSchedule(),
          name: 'calendar-alt',
          title: 'Pending schedule',
          color: this.color,
          scale: '0.8'
        },
        {
          display: this.jobObj.getTechSideSent(),
          name: 'user-check',
          title: 'TechSIDE sent',
          color: shedulerColors.success,
          scale: '1.2'
        }
      ].filter(prop('display'))
    },
    isActive() {
      return eqIds(this.getJobDetailsId)(this.job.id)
    }
  },
  components: { JobCardInfoBlock, JobConfirmationDetails, SchedulerTimesRow, SchedulerVendors }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerJobCard';
#watermark-cancel {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
  z-index: 99;
  color: red;
}

#watermark-reschedule {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.5;
  z-index: 99;
  color: purple;
}

.notification {
  position: absolute;
  top: -4px;
  font-weight: bold;
  color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  height: 20px;
  min-width: 20px;
  padding-top: 1px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 0.9em;
  z-index: 99;
  &.orange {
    background: #ff7b00;
  }
  &.purple {
    background: #9834eb;
  }
  &.light-blue {
    background: #34cdeb;
  }
  &.teal {
    background: #04cc94;
  }
  &.green {
    background: green;
  }
  &.blue {
    background: blue;
  }
  &.brown {
    background: #735130;
  }
  &.right {
    right: -4px;
  }
  &.left {
    left: -1px;
  }
  &.white {
    background: white;
    color: black;
  }
  &.yellow {
    background: rgb(192, 149, 29);
  }
  &.dark-purple {
    background: #3a0046;
  }
  &.dark-blue {
    background: #004646;
  }
}
</style>
