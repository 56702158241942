import { gql } from 'apollo-boost'
import { JOBTAX_FRAGMENT } from '../fragments/tax.fragment'
import { DOCUMENT_FRAGMENT } from '../fragments/docs.fragment'
import { NOTE_FRAGMENT } from '../fragments/note.fragment'
import { JOBTAG_FRAGMENT } from '../fragments/tag.fragment'
import { PAYMENT_FRAGMENT } from '../fragments/payment.fragment'
import { AUDIT_LOG_ENTRY_FRAGMENT } from '../fragments/audit-log-entry.fragment'

export const JOB_TAXES_QUERY = gql`
  query jobTaxes($id: ID!, $draft_id: ID) {
    jobTaxes(id: $id, draft_id: $draft_id) {
      ...JobtaxFields
    }
  }
  ${JOBTAX_FRAGMENT}
`

export const JOB_DOCS_QUERY = gql`
  query jobDocs($id: ID!, $draft_id: ID) {
    jobDocs(id: $id, draft_id: $draft_id) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FRAGMENT}
`

export const JOB_TAGS_QUERY = gql`
  query jobTags($id: ID!, $draft_id: ID) {
    jobTags(id: $id, draft_id: $draft_id) {
      ...JobtagFields
    }
  }
  ${JOBTAG_FRAGMENT}
`

export const JOB_NOTES_QUERY = gql`
  query jobNotes($id: ID!, $draft_id: ID) {
    jobNotes(id: $id, draft_id: $draft_id) {
      ...NoteFields
    }
  }
  ${NOTE_FRAGMENT}
`

export const JOB_PAYMENTS_QUERY = gql`
  query jobPayments($id: ID!, $draft_id: ID) {
    jobPayments(id: $id, draft_id: $draft_id) {
      ...PaymentFields
    }
  }
  ${PAYMENT_FRAGMENT}
`

export const AUDIT_LOG_QUERY = gql`
  query jobAuditLog($id: ID, $draft_id: ID, $created: Int, $modified: Int) {
    jobAuditLog(id: $id, draft_id: $draft_id, created: $created, modified: $modified) {
      ...AuditLogEntryFields
    }
  }
  ${AUDIT_LOG_ENTRY_FRAGMENT}
`
