import * as R from 'ramda'

const JobDetailsItemsMixin = {
  computed: {
    infoRows() {
      return this.jobDetails
        ? [
          [
            {
              icon: 'user-tie',
              label: 'Customer',
              text: this.job.getTitle()
            },
            {
              icon: 'user-cog',
              label: 'Installer',
              text: this.shopTechs.find((temp) => {
                return this.job?.vendor?.[0]?.gb_vendor_id === temp.id
            }),
              dataType: 'tech-select',
              placeholder: 'Unassigned',
              selectKey: 'name',
              options: this.shopTechs,
              updateKey: 'gb_tech_id'
            },
            ...(this.checkCommissionPermission
              ? [
                {
                  icon: 'receipt',
                  label: 'Commission',
                  text: this.job.getCommision(),
                  dataType: 'commission'
                }
              ]
              : []),
            {
              icon: 'user-cog',
              label: 'Bay',
              text: this.bays.find(R.propEq('id', this.job.bay_id)),
              dataType: 'bay-select',
              placeholder: 'Unassigned',
              selectKey: 'name',
              options: this.bays,
              updateKey: 'bay_id'
            },
            { icon: 'car', label: 'Vehicle', text: this.job.getVehicle() },
            {
              icon: 'phone',
              label: 'Phone',
              text: this.job.getInstallPhone(),
              dataType: 'phone',
              customerName: this.job.getTitle(),
              customerId: this.job.getCustomerId()
            }
          ],
          [
            {
              icon: 'map-marker-alt',
              label: 'Address',
              text: {
                address: this.job.install_g_address,
                install_location: this.job.install_location,
                install_location_id: this.job.install_location_id
              },
              dataType: 'address'
            }
          ],
          [
            {
              icon: 'warehouse',
              label: 'Install Location',
              text: this.job.getInstallLocationName()
            }
          ],
          [
            {
              icon: 'home',
              label: 'Delivery Type',
              text: this.job.install_context,
              dataType: 'install_context',
              updateKey: 'install_context'
            }
          ],
          [
            {
              icon: 'calendar',
              label: 'Scheduled date',
              text: this.job.getScheduledDate(),
              dataType: 'date',
              updateKey: 'scheduled_date'
            }
          ],
          [
            {
              icon: 'clock',
              label: 'Timerange',
              start: this.job.getScheduledStartTime(),
              dataType: 'time',
              date: this.job.getScheduledDate(),
              end: this.job.getScheduledEndTime()
            }
          ],
          [
            {
              icon: 'cogs',
              label: 'Parts',
              dataType: 'parts',
              text: this.job.getSchedulerParts(),
            }
          ],
          [
            {
              icon: 'comment-alt',
              label: 'Sales Rep notes',
              text: this.job.getSalesrepNotes(),
              newLine: true
            },
            {
              icon: 'comment-alt',
              label: 'Technician notes',
              text: this.job.getTechnicianNotes(),
              newLine: true
            }
          ],
          [
            {
              icon: 'sticky-note',
              label: 'Install notes',
              text: this.job.getInstallNotes(),
              dataType: 'textarea',
              updateKey: 'install_notes',
              newLine: true
            }
          ],
          [
            {
              icon: 'paste',
              label: 'Inspection',
              text: this.job.getPendingInspection(),
              dataType: 'checkbox',
              updateKey: 'pending_inspection'
            },
            {
              icon: 'cart-arrow-down',
              label: 'Parts on backorder',
              text: this.job.getPartsOnBackorder(),
              dataType: 'checkbox',
              updateKey: 'parts_on_backorder'
            },
            {
              icon: 'calendar-alt',
              label: 'Pending Schedule',
              text: this.job.getPendingSchedule(),
              dataType: 'checkbox',
              updateKey: 'pending_schedule'
            },
            {
              icon: 'calendar-minus',
              label: 'Reschedule',
              text: this.job.getCustomerReschedule(),
              dataType: 'checkbox',
              updateKey: 'customer_reschedule'
            },
            {
              icon: 'calendar-times',
              label: 'Cancel',
              text: this.job.getCustomerCancel(),
              dataType: 'checkbox',
              updateKey: 'customer_cancel'
            }
          ],
          [
            {
              icon: 'tags',
              label: 'Tags',
              text: this.job.getTags(),
              newLine: true,
              dataType: 'tags'
            }
          ],
          [
            {
              icon: 'file',
              label: 'Documents',
              text: this.job.getDocuments(),
              newLine: true,
              dataType: 'documents'
            }
          ],
          [
            {
              icon: 'comment-alt',
              label: 'Notes',
              text: this.job.getNotes(),
              newLine: true,
              dataType: 'notes'
            }
          ]
        ]
        : []
    }
  }
}

export default JobDetailsItemsMixin
