import { JOB_DETAILS_QUERY } from '@/apollo/queries/job-details.query'
import * as R from 'ramda'
import {
  CREATE_DRAFT_MUTATION,
  CREATE_JOB_MUTATION,
  SAVE_DRAFT_MUTATION,
  SAVE_JOB_MUTATION
} from '@/apollo/mutation/job.mutation'
import { sync } from 'vuex-pathify'
import { updateFieldMutate } from '@/scripts/helpers/apollo.helpers'
import debounce from 'lodash.debounce'
import { getConsumerName } from '@/scripts/helpers/consumer.helpers'
import diff from 'object-diff'

const JobDetailsMixin = {
  data() {
    return {
      job: {},
      loadingStore: false
    }
  },
  apollo: {
    job: {
      query: JOB_DETAILS_QUERY,
      variables() {
        return {
          id: this.$route.params.id || 'new',
          draft_id: this.$route.params.draft_id
        }
      },
      watchLoading(v) {
        this.jobLoading = v
      }
    }
  },
  methods: {
    async cleanup() {
      try {
        this.loadingStore = true
        const client = this.$apollo.getClient()
        await client.resetStore()
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingStore = false
      }
    },
    createDraft: debounce(
      async function(job) {
        if (this.isNotDraftAndNotPublished) {
          this.draftLoading = true
          if (
            this.shop?.default_invoice_tier &&
            !this.customer?.default_invoice_tier
          ) {
            this.updateJob({
              selected_invoice_tier: this.shop.default_invoice_tier
            })
          }
          this.globalLoading = true

          const { data } = await this.$apollo.mutate({
            mutation: CREATE_DRAFT_MUTATION,
            variables: { job }
          })

          const draft = data?.createDraft
          const draft_id = draft.id
          await this.$apollo.query({
            query: JOB_DETAILS_QUERY,
            variables: { id: 'new', draft_id: `${draft_id}` }
          })
          this.$router.replace(`/jobs/new/${draft_id}`)
          this.updateJob(R.pick(['consumer_id', 'bill_to_id'])(draft))
          if (this.customer) {
            this.updateJob(
              {},
              {
                label: 'Set customer to',
                value: getConsumerName(this.customer)
              }
            )
          }
          if (this.billTo) {
            this.updateJob(
              {},
              {
                label: 'Set bill to to',
                value: getConsumerName(this.customer)
              }
            )
          }
          if (this.vehicle) {
            this.updateJob(
              {},
              {
                label: 'Set vehicle to',
                value: `${this.vehicle.year} ${this.vehicle.make} ${this.vehicle.model}`
              }
            )
          }
          this.draftLoading = false
          this.globalLoading = false
        }
      },
      1000,
      { leading: false }
    ),
    async updateJob(data, audit) {
      if ('install_g_address' in data) {
        this.$job.updateZipTaxes(data.install_g_address, false, false, data.install_context)
      }

      if ('install_context' in data && this.$job?.hasId) {
        this.$job.updateMobileTaxes(data.install_context);
      }

      // intercept rates and create them past here
      if (data.rates) {
        await this.$job.applyRate(data.rates)
        delete data.rates
        if (Object.keys(data).length === 0) {
          return
        }
      }

      if (data.rates_id) {
        delete data.rates
        if (Object.keys(data).length === 0) {
          return
        }
      }

      const id = R.ifElse(
        R.path(['params', 'draft_id']),
        R.path(['params', 'draft_id']),
        R.pipe(R.path(['params', 'id']), R.when(R.equals('new'), R.always(0)))
      )(this.$route)
      const { cache } = this.$apollo.getClient()
      var modifyData = {
        id: `Job:${id}`,
        fields: updateFieldMutate(data)
      }
      cache.modify(modifyData)

      if (audit && id) {
        await this.autosave(data, audit)
      }
    },
    async autosave(v, audit) {
      this.jobSaving = true
      try {
        await this.$apollo.mutate(
          this.isDraft
            ? {
              mutation: SAVE_DRAFT_MUTATION,
              variables: {
                job: {
                  ...v,
                  id: +this.isDraft
                },
                ...(typeof audit === 'object' ? { audit } : {})
              }
            }
            : {
              mutation: SAVE_JOB_MUTATION,
              variables: {
                job: {
                  ...v,
                  id: +this.$route.params.id
                },
                ...(typeof audit === 'object' ? { audit } : {})
              }
            }
        )
      } catch (error) {
        console.log(error)
      }
      this.jobSaving = false
    },
    async createJob() {
      const { data } = await this.$apollo.mutate({
        mutation: CREATE_JOB_MUTATION,
        variables: {
          job: R.omit([
            'job_gb_csr',
            'job_gb_salesource',
            'job_gb_salesrep',
            'job_gb_tech',
            'job_gb_location'
          ])(this.job)
        }
      })
      if (data?.createJob) {
        this.$router.replace(`/jobs/${data?.createJob.id}`)
      }
    },
    createCompareRates(passedInRates) {
      const conf = [
        'standard_kit',
        'high_kit',
        'fast_kit',
        'safelite_recals',
        'lynx_rtl_recals',
        'lynx_dlr_recals',
        'discounts'
      ]
      var compareRates = R.clone(passedInRates)
      conf.map(async key => {
        if (!compareRates[key]) {
          if (key.indexOf('_kit') >= 0) {
            compareRates[key] = { __typename: 'Kit', type: 'each', amount: 0 }
          } else if (key.indexOf('_recals') >= 0) {
            compareRates[key] = {
              __typename: 'Recals',
              dynamic: 0,
              static: 0,
              dual: 0
            }
          } else if (key.indexOf('discounts') >= 0) {
            compareRates[key] = {
              __typename: 'Discounts',
              glass: 0,
              moulding: 0,
              oem: 0,
              tempered: 0
            }
          }
        }
      })
      return R.omit(['id', 'name'], compareRates)
    },
    setDefaults(job) {
      const gb_csr_id = this.$store.state.user.csrdefaults?.find(
        R.propEq('organization_id', this.shop.organization_id)
      )?.gb_csr_id
      return { ...job, gb_csr_id }
    }
  },
  computed: {
    jobLoading: sync('jobDetails/loading@job'),
    globalLoading: sync('globalLoading'),
    jobSaving: sync('jobDetails/saving@job'),
    draftLoading: sync('jobDetails/loading@draft'),
    drawer: sync('jobDetails/drawer'),
    isDraft() {
      return this.$route.params.draft_id
    },
    isPublished() {
      return this.$route.params.id !== 'new'
    },
    isNotDraftAndNotPublished() {
      return (
        R.allPass([
          R.pathEq(['params', 'id'], 'new'),
          R.complement(R.path(['params', 'draft_id']))
        ])(this.$route) &&
        !this.draftLoading
      )
    },
    hasId() {
      return R.allPass([
        R.anyPass([
          R.allPass([
            R.pathEq(['params', 'id'], 'new'),
            R.path(['params', 'draft_id'])
          ]),
          R.complement(R.pathEq(['params', 'id'], 'new'))
        ]),
        R.propEq('name', 'jobpage')
      ])(this.$route)
    },
    locked() {
      return !!this.job.edi_locked
    },
    selectedJobShopRate() {
      var foundLevel = ''
      var foundRate = null

      if (this.$job.rates && this.shopRates) {
        for (var i = 0; i < this.shopRates.length; i++) {
          if (
            R.equals(
              this.createCompareRates(this.$job.rates),
              this.createCompareRates(this.shopRates[i])
            )
          ) {
            if (this.job.display_rates_id === this.shopRates[i].id) {
              foundLevel = 'displayRate'
              foundRate = this.shopRates[i]
            } else if (
              !this.job.display_rates_id ||
              this.job.display_rates_id <= 0
            ) {
              if (
                foundLevel !== 'displayRate' &&
                this.$job?.billToShopRate?.id === this.shopRates[i].id
              ) {
                foundLevel = 'billTo'
                foundRate = this.shopRates[i]
              } else if (
                foundLevel !== 'displayRate' &&
                foundLevel !== 'billTo' &&
                this.$job?.customerShopRate?.id === this.shopRates[i].id
              ) {
                foundLevel = 'customer'
                foundRate = this.shopRates[i]
              } else if (
                foundLevel !== 'displayRate' &&
                foundLevel !== 'billTo' &&
                foundLevel !== 'customer' &&
                this.$job?.defaultShopRate?.id === this.shopRates[i].id
              ) {
                foundLevel = 'shop'
                foundRate = this.shopRates[i]
              } else if (foundRate == null) {
                foundRate = this.shopRates[i]
              }
            }
          }
        }
      }
      return foundRate
    }
  },
  watch: {
    job(v, old) {
      const draftCreateKeys = ['consumer_id', 'bill_to_id', 'vehicle_id']

      if (
        R.pipe(
          diff,
          R.filter(R.identity),
          R.keys,
          R.intersection(R.__, draftCreateKeys),
          R.length
        )(old, v)
      ) {
        this.createDraft(v)
      }
    }
  }
}

export default JobDetailsMixin
