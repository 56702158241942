import * as R from 'ramda'

const OR = R.flip(R.or)

const ratesDefault = OR({
  __typename: 'Rates',
  id: null,
  name: '',
  chip_first: 0,
  chip_additional: 0,
  labor_flat: 0,
  labor_hourly: 0,
  labor_flat_tempered: 0,
  labor_hourly_tempered: 0,
  discounts: {
    __typename: 'Discounts',
    glass: 0,
    moulding: 0,
    oem: 0,
    tempered: 0
  },
  standard_kit: { __typename: 'Kit', type: 'each', amount: 0 },
  high_kit: { __typename: 'Kit', type: 'each', amount: 0 },
  fast_kit: { __typename: 'Kit', type: 'each', amount: 0 },
  safelite_recals: {
    __typename: 'Recals',
    dual: 0,
    dynamic: 0,
    static: 0
  },
  lynx_rtl_recals: {
    __typename: 'Recals',
    dual: 0,
    dynamic: 0,
    static: 0
  },
  lynx_dlr_recals: {
    __typename: 'Recals',
    dual: 0,
    dynamic: 0,
    static: 0
  }
})

const typePolicies = {
  Query: {
    fields: {
      Jobtags: {
        merge: (exs, inc) => inc
      },
      Job: {
        merge: (existing, incoming, opts) =>
          opts.mergeObjects(existing, incoming),
      },
    }
  },
  Consumer: {
    fields: {
      rates: ratesDefault,
      po_required: OR(0),
      auto_add_adhesive: OR(0),
      tax_exempt: OR(0),
      company_name: OR(''),
      phones: OR([]),
      emails: OR([]),
      addresses: OR([])
    }
  },
  Job: {
    fields: {
      rates: ratesDefault,
      total_list_price: OR(0),
      total_materials: OR(0),
      total_hours: OR(0),
      total_labor: OR(0),
      total_subtotal: OR(0),
      total_cost: OR(0),
      total_materials_taxes: OR(0),
      total_labor_taxes: OR(0),
      total_taxes: OR(0),
      total_after_taxes: OR(0),
      total_after_deductible: OR(0),
      total_margin: OR(0),
      total_sale_source_commission: OR(0),
      total_sales_rep_commission: OR(0),
      total_tech_commission: OR(0),
      total_location_commission: OR(0),
      total_commission: OR(0),
      total_balance_after_payments: OR(0),
      total_deductible_after_payments: OR(0),
      total_balance_adjustable_payments: OR(0),
      total_csr_commission: OR(0),
      customer_rebate: OR('0'),
      other_costs: OR('0'),
      install_context: OR('mobile'),
      insurance_fleet_po_required: OR(0),
      insurance_fleet_auto_add_adhesive: OR(0),
      insurance_fleet_tax_exempt: OR(0),
      consumer_id: OR(0),
      bill_to_id: OR(0),
      selected_invoice_tier: OR('dor'),
      install_g_address: (address, { toReference }) => {
        if (address) {
          return (
            toReference(address) ||
            toReference({ __typename: 'GAddress', ...address })
          )
        } else {
          return null
        }
      }
    }
  },
  Jobtax: {
    keyFields: ['shop_tax_id'],
    fields: {
      amount: (_, { readField }) => readField('materials') + readField('labor'),
      labor: OR(0),
      materials: OR(0),
      tax: {
        merge: OR
      }
    }
  },
  Jobtag: {
    keyFields: ['shop_tag_id'],
    fields: {
      tag: {
        merge: OR
      }
    }
  },
  Tax: {
    fields: {
      tax_zip_codes: {
        merge: OR
      }
    }
  },
  Part: {
    fields: {
      materials_tax: OR(0),
      labor_tax: OR(0),
      total_tax: OR(0),
      is_addl_chip: (_, { readField }) =>
        R.or(
          _,
          readField('is_chip') === '1' &&
            readField('description') === "Add'l Chip Repair"
            ? '1'
            : '0'
        )
    }
  }
}

export default typePolicies
