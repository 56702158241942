<template>
  <div
    :class="{
      'scheduler-job-card-list': true,
      'row-view': isRowView,
      'no-drop': !enabled
    }"
  >
    <div
      class="job-list-card-header"
      :class="{
        'min-height': minHeight
      }"
    >
      <scheduler-tech-header :route="route" v-if="route" />
      <scheduler-job-list-filter v-else />
    </div>
    <draggable
      v-model="entriesList"
      class="job-card-list-container"
      :options="draggableOptions"
    >
      <div
        v-for="item in entriesList"
        :key="item.id"
        class="card-wrapper"
        @dragstart="itemDrag(item)"
        @dragend="itemDrag()"
      >
        <scheduler-job-card
          v-if="item.job"
          :job="item.job"
          :routeEntry="item"
          :color="color(item)"
          :cardStyle="cardStyle(item)"
          :noDate="!!route"
        />
        <scheduler-generic-card
          v-else
          :color="color(item)"
          :cardStyle="cardStyle(item, true)"
          :item="item"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
import SchedulerJobCard from './SchedulerJobCard'
import Draggable from 'vuedraggable'
import { isMobile } from '@/scripts/helpers/helpers'
import { getItemColor } from '@/scripts/helpers/scheduler_v3.helpers'
import SchedulerJobListFilter from './filters/SchedulerJobListFilter'
import SchedulerGenericCard from './SchedulerGenericCard'
import { getCardTextColor } from '@/scripts/helpers/scheduler.helpers'
import { get, call, sync } from 'vuex-pathify'
import actions from '@/store/modules/scheduler_v3/types'
import * as R from 'ramda'
import SchedulerTechHeader from './helpers/SchedulerTechHeader'

export default {
  props: ['route', 'list', 'minHeight'],
  components: {
    SchedulerJobCard,
    Draggable,
    SchedulerJobListFilter,
    SchedulerGenericCard,
    SchedulerTechHeader
  },
  computed: {
    isRowView: get('sch_v3/isRowView'),
    isSolidCardView: get('sch_v3/isSolidCardView'),
    showTotals: get('sch_v3/filters@showTotals'),
    jobTypes: get('sch_v3/filters@jobTypes'),
    tech() {
      return this.route && this.route.tech
        ? this.route.tech
        : { name: 'Unassigned' }
    },
    isCombined() {
      return this.route && this.route.length
    },
    entriesList: {
      get() {
        const list = this.route ? this.route.route_entries : this.list
        const filteredByType = list.filter((item) => this.jobTypes.includes(item.job.status))
        return filteredByType
      },
      set(items) {
        this.$emit('input', items)
        this.updateSchedulerItems({ items, route: this.route })
      }
    },
    color() {
      return function(item) {
        return getItemColor(item, this.route)
      }
    },

    draggableOptions() {
      return {
        group: {
          name: this.enabled ? 'default' : 'disabled',
          ...(this.list ? { put: false } : {})
        },
        chosenClass: 'dragging',
        ...(isMobile() ? { delay: 300 } : {})
      }
    },
    cardStyle() {
      return function(item, reverse) {
        const solid = reverse ? !this.isSolidCardView : this.isSolidCardView
        return solid
          ? {
            color: getCardTextColor(item.color || '#607D8B'),
            background: item.color || '#607D8B'
          }
          : {}
      }
    },
    basicTech() {
      return this.tech && this.tech.id !== 0 && !this.tech.techs
    },
    hasAccessToTextMessaging() {
      return this.$store.state.user.has_access_to_twilio_phone_numbers
    },
    context: sync('sch_v3/filters@context')
  },
  data() {
    return {
      openingTechDialog: false,
      waypointsOpen: false,
      enabled: true
    }
  },
  methods: {
    itemDrag(item) {
      this.$root.$emit('dragging-item', item)
    },
    toggleWaypointList(value) {
      this.waypointsOpen = value
    },
    createJobPdfs() {
      this.$root.$emit('openSchedulerPdfDialog', this.route)
    },
    updateSchedulerItems: call(actions.updateSchedulerItems)
  },
  mounted() {
    this.$root.$on('dragging-item', item => {
      const itemOrgId = R.ifElse(
        R.path(['gb_route_org_id']),
        R.path(['gb_route_org_id']),
        R.path(['job', 'organization_id'])
      )(item)
      const routeOrgId = R.prop('org_id')(this.route)
      if (!item) {
        this.enabled = true
      } else {
        this.enabled = itemOrgId === routeOrgId
      }
    })
    this.context = 'all'
  },
  beforeDestroy() {
    this.$root.$off('dragging-item')
  }
}
</script>

<style lang="scss" scoped>
@import './styles/SchedulerJobCardList';
</style>

<style>
.test-class {
  background: #000;
}
.jobPdfsDownloadButton {
  color: #81c784;
}
.jobPdfsDownloadButton:hover {
  cursor: pointer;
}
.textMessagingButton {
  color: #e6a23c;
}
.textMessagingButton:hover {
  cursor: pointer;
}
</style>
