import Vue from 'vue'
import * as R from 'ramda'
import SchedulerShopIcon from '@/components/SchedulerV3/helpers/map-icons/SchedulerShopIcon'
import SchedulerTechIcon from '@/components/SchedulerV3/helpers/map-icons/SchedulerTechIcon'
import SchedulerRouteTechStopIcon from '@/components/SchedulerV3/helpers/map-icons/SchedulerRouteTechStopIcon'
import SchedulerVendorIcon from '@/components/SchedulerV3/helpers/map-icons/SchedulerVendorIcon'

const ShopIconConstructor = Vue.extend(SchedulerShopIcon)

export const getShopIconUrl = () => {
  const iconComponent = new ShopIconConstructor()
  iconComponent.$mount()
  const iconDom = iconComponent.$el
  const iconString = new XMLSerializer().serializeToString(iconDom)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

const getShopIcon = () => ({
  anchor: { x: 23, y: 19 },
  url: getShopIconUrl()
})

const TechIconConstructor = Vue.extend(SchedulerTechIcon)

export const getTechIconUrl = ({ details }) => {
  const iconComponent = new TechIconConstructor({
    propsData: { fillColor: details.color }
  })
  iconComponent.$mount()
  const iconDom = iconComponent.$el
  const iconString = new XMLSerializer().serializeToString(iconDom)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

const getTechIcon = tech => ({
  anchor: { x: 20, y: 19 },
  url: getTechIconUrl(tech)
})

const VendorIconConstructor = Vue.extend(SchedulerVendorIcon)

export const getVendorIconUrl = ({ details }) => {
  const iconComponent = new VendorIconConstructor({
    propsData: { fillColor: details.color }
  })
  iconComponent.$mount()
  const iconDom = iconComponent.$el
  const iconString = new XMLSerializer().serializeToString(iconDom)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

const getVendorIcon = vendor => ({
  anchor: { x: 23, y: 19 },
  url: getVendorIconUrl(vendor)
})

const RouteTechStopIconConstructor = Vue.extend(SchedulerRouteTechStopIcon)

export const getRouteTechStopIconUrl = ({ details }) => {
  const iconComponent = new RouteTechStopIconConstructor({
    propsData: { fillColor: details.color }
  })
  iconComponent.$mount()
  const iconDom = iconComponent.$el
  const iconString = new XMLSerializer().serializeToString(iconDom)
  return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(iconString)
}

const getRouteTechStopIcon = routeTechStop => ({
  anchor: { x: 15, y: 19 },
  url: getRouteTechStopIconUrl(routeTechStop)
})

const getStops = key =>
  R.pipe(
    R.filter(R.path([key, 'id'])),
    R.groupBy(R.path([key, 'id'])),
    R.values,
    R.map(
      R.applySpec({
        details: R.path([0, key]),
        stops: R.map(R.dissoc(key)),
        type: () => key
      })
    )
  )
export const getShopStops = R.pipe(
  R.filter(R.prop('shop_id')),
  R.groupBy(R.prop('shop_id')),
  R.values,
  R.map(
    R.applySpec({ shop: R.path([0, 'shop']), stops: R.map(R.dissoc('shop')) })
  )
)

export const getCombinedMapStops = R.pipe(
  R.prop('data'),
  R.filter(R.complement(R.prop('hidden'))),
  R.pluck('route_entries'),
  R.values,
  R.unnest,
  R.applySpec({
    shops: getStops('shop'),
    techs: getStops('tech'),
    vendors: getStops('vendor'),
    route_tech_stops: getStops('route_tech_stop')
  })
)

export const getMapStopIcon = R.cond([
  [R.propEq('type', 'shop'), getShopIcon],
  [R.propEq('type', 'tech'), getTechIcon],
  [R.propEq('type', 'vendor'), getVendorIcon],
  [R.propEq('type', 'route_tech_stop'), getRouteTechStopIcon]
])

export const getTechsideUsernames = R.pipe(
  R.prop('data'),
  R.map(R.prop('techside_username')),
  R.values,
  R.uniq,
  R.filter(R.identity)
)
